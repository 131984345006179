import api from "@/api/api";
import { SimulationSchema, SimulationType } from "@/model/simulation.typing";
import { CreateSimulationForm } from "@/query/simulation.query";
import { z } from "zod";

export async function fetchSimulations(organizationId?: number, signal?: AbortSignal): Promise<SimulationType[]> {
  const url =
    organizationId !== undefined && organizationId !== null
      ? `/v1/simulations?organization_id=${organizationId}`
      : "/v1/simulations";
  const response = await api.get<{ items: unknown }>(url, { signal });
  return z.array(SimulationSchema).parse(response.data.items);
}

export async function requestCreateSimulation(
  organizationId: number,
  fieldId: string,
  fieldGeneration: number,
  form: CreateSimulationForm
): Promise<string> {
  const resutl = await api.post<{ id: string }>("/v1/simulations", {
    organization_id: organizationId,
    case_study_id: fieldId,
    case_study_generation: fieldGeneration,
    parameters: {
      id_parcel: typeof form.id_parcel !== "string" || form.id_parcel === "" ? undefined : form.id_parcel,
      start_year: form.start_year,
      years_of_simulation: form.years_of_simulation,
      plant_growth_model: form.plant_growth_model,
      execute_hydro_model: form.execute_hydro_model,
    },
    outputs: {
      hydro: form.outputs_hydro,
      nitrogen: form.outputs_nitrogen,
      carbon: form.outputs_carbon,
    },
  });
  return resutl.data.id;
}

export async function requestDownLoadLink(organizationId: number, simulationId: string): Promise<string> {
  const params = new URLSearchParams();
  params.set("organization_id", organizationId.toString());
  params.set("simulation_id", simulationId);
  const response = await api.get<{ url: string }>(`/v1/simulations/download-link?${params.toString()}`);
  return response.data.url;
}
