import { PageContainer } from "@/components/PageContainer";

import { CheckboxForm } from "@/components/form/CheckboxForm";
import FormError from "@/components/form/formError";
import FormInput from "@/components/form/formInput";
import { RadioGroupForm } from "@/components/form/radioGroupForm";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { fieldGenerationDetailQuery, fieldListQuery } from "@/query/field.query";
import { useCreateSimulationMutation } from "@/query/simulation.query";
import { useCurrentOrganizationStore } from "@/store/useCurrentOrganizationStore";
import { QueryClient } from "@tanstack/react-query";
import { ArrowLeft } from "lucide-react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";
import { ParcelCombobox } from "./common/parcelCombobox";

export function loader(queryClient: QueryClient) {
  return async ({ params, request }: LoaderFunctionArgs) => {
    const fieldId = params["fieldId"];
    const generation = parseInt(params["generation"] ?? "", 10);
    // get serach params named parcelId from the request object

    const url = new URL(request.url);
    const parcelId = url.searchParams.get("parcelId");

    if (
      fieldId === undefined ||
      fieldId === null ||
      generation === undefined ||
      generation === null ||
      isNaN(generation)
    ) {
      throw redirect("/error/404");
    }

    const fields = await queryClient.fetchQuery(fieldListQuery()); // todo change this to use getfield by Id once api is fixed
    // todo change this to use getfield by Id once api is fixed
    const field = fields.find((field) => field.id === fieldId);
    if (field === undefined || field === null) {
      throw redirect("/error/404");
    }
    const fieldGenerationInfo = await queryClient.fetchQuery(fieldGenerationDetailQuery(field.id, generation));
    const fieldGeneration = field.generations.find((g) => g.generation === generation);
    if (fieldGeneration === undefined || fieldGeneration === null) {
      throw redirect("/error/404");
    }
    return { field, fieldGeneration, fieldGenerationInfo, parcelId };
  };
}

export function AddSimulationPage() {
  const { t } = useTranslation();
  const { currentOrganization } = useCurrentOrganizationStore();
  const organizationId = currentOrganization?.id as number;
  const { field, fieldGeneration, fieldGenerationInfo, parcelId } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  const { form, onSubmit, i18nErrorMessage, isLoading } = useCreateSimulationMutation(
    organizationId,
    field.id,
    fieldGeneration.generation,
    fieldGenerationInfo,
    parcelId
  );

  const executerOneParcel = useWatch({ name: "executerOneParcel", control: form.control });
  const maxNumberOfYears = fieldGenerationInfo.max_year - fieldGenerationInfo.min_year + 1;

  return (
    <PageContainer
      title={field.name}
      actionsRender={() => {
        return (
          <Button asChild>
            <Link to="/app/case-study" className="flex flex-nowrap items-center space-x-1">
              <ArrowLeft className="h-5 w-5" />
              <span>{t("add-simulation.back-to-fields")}</span>
            </Link>
          </Button>
        );
      }}
    >
      <Form {...form}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form noValidate={true} onSubmit={onSubmit} className="space-y-6">
          <div className="space-y-6 py-2 pb-4">
            <FormField
              control={form.control}
              name="start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("add-simulation.fields.startYear")}</FormLabel>
                  <FormControl>
                    <FormInput
                      type="number"
                      min={fieldGenerationInfo.min_year}
                      max={fieldGenerationInfo.max_year}
                      step={1}
                      placeholder={t("add-simulation.fields.chooseYear")}
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>{t("add-simulation.fields.startYearDescription")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="years_of_simulation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("add-simulation.fields.simulationYears")}</FormLabel>
                  <FormControl>
                    <FormInput
                      type="number"
                      min={1}
                      max={maxNumberOfYears}
                      step={1}
                      placeholder={t("add-simulation.fields.chooseNbrOfYears")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <RadioGroupForm
              form={form}
              name="plant_growth_model"
              label={t("add-simulation.fields.plantGrowthModel")}
              defaultValue="AqYieldNC"
              options={[
                { id: "AqYieldNC", name: "AqYieldNC" },
                { id: "AqYield", name: "AqYield" },
              ]}
            />
            <CheckboxForm
              form={form}
              className="rounded-md border p-4"
              name="execute_hydro_model"
              label={t("add-simulation.fields.hydroModel")}
              description={t("add-simulation.fields.hydroModelDescription")}
            />

            <div className="flex flex-col gap-4 rounded-md border p-4">
              <CheckboxForm
                form={form}
                name="executerOneParcel"
                label={t("add-simulation.fields.executerOneParcel")}
                description={t("add-simulation.fields.executerOneParcelDescription")}
              >
                {executerOneParcel ? (
                  <FormField
                    control={form.control}
                    name="id_parcel"
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormLabel>{t("add-simulation.fields.parcelId")}</FormLabel>
                        <FormControl>
                          <ParcelCombobox
                            fieldGeneration={fieldGeneration.generation}
                            fieldId={field.id}
                            parcelId={formField.value}
                            onSelect={(value) => formField.onChange(value)}
                          />
                        </FormControl>
                        <FormDescription>{t("add-simulation.fields.parcelIdDescription")}</FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : null}
              </CheckboxForm>
            </div>

            <div className="flex flex-col gap-2 rounded-md border p-4">
              <FormLabel className="mb-2">{t("add-simulation.fields.output")}</FormLabel>
              <CheckboxForm form={form} name="outputs_hydro" label="Hydro" />
              <CheckboxForm form={form} name="outputs_nitrogen" label="Nitrogen" />
              <CheckboxForm form={form} name="outputs_carbon" label="Carbon" />
            </div>

            {i18nErrorMessage ? <FormError error={t(i18nErrorMessage)} /> : null}
          </div>

          <DialogFooter>
            <Button type="submit" disabled={isLoading}>
              {t("add-simulation.simulate")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </PageContainer>
  );
}
